<template>
  <div class="md-layout-item md-size-100">
    <CharactersLevelUpPricesTable />
  </div>
</template>

<script>
export default {
  components: {
    CharactersLevelUpPricesTable: () =>
      import("@/components/Tables/CharactersLevelUpPricesTable"),
  },
};
</script>
